import {Box, Button, Center, Heading, VStack} from '@chakra-ui/react'
import Link from 'next/link'
import {FiArrowRight} from 'react-icons/fi'

import ClientLogoGrid from 'components/client-logo-grid'
import FeatureCards from 'components/feature-cards'
import HeroImage from 'components/hero-image'
import ALink from 'components/link'
import WistiaVideo from 'components/wistia'

import {colors} from 'config/colors'
import {blueRadialGradient, grayRadialGradient, space} from 'config/theme'

import Layout from 'layouts/main'

const clientsTitle = 'Meet some of our clients'
const clientsSubtitle =
  'Making forward-thinking cities around the globe faster, smarter, and more efficient.'
const wisitaVideoId = 'ts8wjmijb7'

export default function HomePage() {
  return (
    <Layout title='Evaluate changes to your public transportation system'>
      <HeroImage title={<>Measure access to opportunities</>} />
      <VStack
        alignItems='center'
        backgroundColor={colors[500]}
        background={blueRadialGradient}
        px={space.md}
        pb={space.xl}
        spacing={space.xl}
        width='100%'
      >
        <Box
          maxHeight={`min(80vh, ${1200 * (9 / 16)}px)`}
          maxWidth={`min(80vh * 16 / 9, 1200px)`}
          mt={{base: '-50px', md: '-100px'}}
          width='100%'
          zIndex={1}
        >
          <WistiaVideo boxShadow='2xl' id={wisitaVideoId} />
        </Box>
        <Heading color='white' size='lg' maxWidth='50ch' textAlign='center'>
          Conveyal is the platform for rapid, detailed accessibility analysis of
          transport and{' '}
          <span style={{whiteSpace: 'nowrap'}}>land-use scenarios.</span>
        </Heading>

        <Box>
          <FeatureCards />
        </Box>

        <VStack spacing={10}>
          <Heading color='white' size='lg' maxWidth='50ch' textAlign='center'>
            Discover what Conveyal can do for your organization
          </Heading>
          <Box>
            <Link href='/learn' passHref>
              <Button size='lg' rightIcon={<FiArrowRight />}>
                Learn more
              </Button>
            </Link>
            <Link href='/contact/new' passHref>
              <Button ml={space.sm} size='lg' rightIcon={<FiArrowRight />}>
                Get started
              </Button>
            </Link>
          </Box>
        </VStack>
      </VStack>

      <Center
        background={grayRadialGradient}
        px={space.md}
        py={space.xl}
        width='100%'
      >
        <VStack spacing={20} maxWidth='1200px'>
          <VStack spacing={space.sm} textAlign='center'>
            <Heading color='blue.800' size='xl'>
              {clientsTitle}
            </Heading>
            <Heading color='blue.800' fontWeight='normal' size='md' maxW='60ch'>
              {clientsSubtitle} <ALink href='/clients'>Read more →</ALink>
            </Heading>
          </VStack>
          <ClientLogoGrid />
        </VStack>
      </Center>
    </Layout>
  )
}
