/**
 * Converts a string to kebab-case.
 * @param str The input string to convert.
 * @returns The kebab-cased string.
 */
export default function toKebabCase(str: string): string {
  return (
    str
      // Replace all underscores and spaces with hyphens
      .replace(/[_\s]+/g, '-')
      // Insert a hyphen before all uppercase letters (not at the start)
      .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
      // Replace multiple hyphens with a single hyphen
      .replace(/-+/g, '-')
      // Convert the entire string to lowercase
      .toLowerCase()
      // Trim hyphens from the start and end
      .replace(/^-+|-+$/g, '')
  )
}
