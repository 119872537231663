const cc = (name: string, logo: string): Conveyal.Client => ({
  name,
  logo
})

const AllClients: Conveyal.Client[] = [
  cc('Massachusetts Department of Transportation', 'massdot.svg'),
  cc('World Bank', 'worldbank.png'),
  cc('New York State Department of Transportation', 'nysdot.png'),
  cc('Movares', 'movares.png'),
  cc('Swiss Federal Railways', 'sbb.png'),
  cc('Arup', 'arup.png'),
  cc('Norwegian National Journey Planner', 'entur.png'),
  cc('Indianapolis Metropolitan Planning Organization', 'indympo.svg'),
  cc('New York Regional Plan Association', 'rpa.png'),
  cc('Movares', 'amsterdam.png'),
  // cc('Montréal Regional Transportation Authority', 'artm.png'),
  cc('Massachusetts Institute of Technology (MIT)', 'mit.png'),
  cc('Aix-Marseille', 'agam.png'),
  cc('Los Angeles Department of Transportation', 'ladot.png'),
  cc('Mid-America Regional Council', 'marc.png'),
  cc('Atlanta-Region Transit Link Authority', 'atl.svg'),
  cc('World Resources Institute', 'wri-text.png'),
  cc('Transportation for America', 't4america.png'),
  cc('Berkeley Lab', 'berkeley_lab.png')
]

export default AllClients
