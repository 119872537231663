import {SimpleGrid} from '@chakra-ui/react'

import {maxWidth, space} from 'config/theme'
import CardLink from './card-link'

const features: {
  title: string
  link: {
    title: string
    href: string
  }
  body: string
}[] = [
  {
    title: 'Build detailed scenarios',
    link: {
      title: 'Learn more',
      href: '/learn#detail'
    },
    body: 'Create multi-modal networks automatically from open data sources, and sketch new scenarios with the details that matter for people walking, cycling, and riding transit. 🚶🚲 🚌'
  },
  {
    title: 'Rapidly compare impacts',
    link: {
      title: 'Learn more',
      href: '/learn#speed'
    },
    body: 'Your team can evaluate scenarios in terms of access to opportunities, accounting for uncertainty and variation in travel experience. Our cutting-edge methods and cloud computation handle millions of origins and destinations in seconds.'
  },
  {
    title: 'Facilitate collaboration',
    link: {
      title: 'Learn more',
      href: '/learn#engagement'
    },
    body: 'Collaborate securely with colleagues and engage stakeholders online, without requiring any software installation beyond a web browser.'
  }
]

export default function FeatureCards() {
  return (
    <SimpleGrid
      minChildWidth='28ch'
      maxWidth={maxWidth}
      spacingX={space.md}
      spacingY={16}
      width='100%'
    >
      {features.map((feature, i) => (
        <CardLink key={i} title={feature.title} link={feature.link}>
          {feature.body}
        </CardLink>
      ))}
    </SimpleGrid>
  )
}
